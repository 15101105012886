import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import { FilledButton } from '@jsluna/button';
import { Display2 } from '@jsluna/typography';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#what-is-an-onboarding-message"
            }}>{`What is an onboarding message?`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#writing-onboarding-messages"
            }}>{`Writing onboarding messages`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "what-is-an-onboarding-message",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-an-onboarding-message",
        "aria-label": "what is an onboarding message permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`What is an onboarding message?`}</h2>
    <p>{`Onboarding messages help users get started with a new feature or product. They should educate, excite and drive users to take actions in the experience.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "writing-onboarding-messages",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#writing-onboarding-messages",
        "aria-label": "writing onboarding messages permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Writing onboarding messages`}</h2>
    <TableOfContents mdxType="TableOfContents">
  <ul>
    <li>
      <a className="ln-c-link" href="#educate-the-user">
        Educate the user
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#talk-about-the-benefits">
        Talk about the benefits
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#encourage-action">
        Encourage action
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#dont-overload-the-user">
        Don't overload the user
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#dont-make-it-sound-too-easy">
        Don't make it sound too easy
      </a>
    </li>
  </ul>
    </TableOfContents>
    <h3 id="educate-the-user">Educate the user</h3>
    <p>{`Clearly explain the new feature or product to the user.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <strong>How Click & Collect works</strong>Pick a slot that works for you
    from over 300 stores, and we'll have your shopping ready and waiting for you
    on the day of your collection.
  </Do>
  <Dont mdxType="Dont">
    <strong>Welcome to Click & Collect</strong>Click & Collect is the easiest
    way to shop.
  </Dont>
    </Guideline>
    <p>{`Make sure users understand what steps they’ll be taking during onboarding and why they should take them.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Add your Nectar card to make it even easier to collect points online.</Do>
  <Dont mdxType="Dont">Add your Nectar card.</Dont>
    </Guideline>
    <h3 id="talk-about-the-benefits">Talk about the benefits</h3>
    <p>{`Explain the benefits of a new feature or product, not just how to use it.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    To start using SmartShop, you need to add your Nectar card. Once you do,
    you’ll earn Nectar points every time you shop.
  </Do>
  <Dont mdxType="Dont">To start using SmartShop, you need to add your Nectar card.</Dont>
    </Guideline>
    <p>{`Be careful not to oversell the benefits of a new feature or product.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    With Visual Search you can use your camera to take a photo, then find
    similar looking products that we offer.
  </Do>
  <Dont mdxType="Dont">
    With Visual Search you can find anything you need by simply taking a photo.
  </Dont>
    </Guideline>
    <h3 id="encourage-action">Encourage action</h3>
    <p>{`Give guidance to help users understand if moving onto the next step is right for them.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    By submitting this application, you’ll begin a credit check which will
    affect your credit score.
  </Do>
  <Dont mdxType="Dont">Submit the application to continue.</Dont>
    </Guideline>
    <p>{`Be encouraging but don’t assume the user will take the next step.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">You can get free delivery on your first order.</Do>
  <Dont mdxType="Dont">You’re just a few clicks away from completing your first order.</Dont>
    </Guideline>
    <h3 id="dont-overload-the-user">Don't overload the user</h3>
    <p>{`Break tasks and information into stages so users aren’t overwhelmed with information.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    Scan the barcode on the item you want to add using the camera on your phone.
  </Do>
  <Dont mdxType="Dont">
    Scan your items, pop them straight into your bag, then scan the QR code when
    you’re ready to check out to transfer your shop to the self-service machine.
  </Dont>
    </Guideline>
    <h3 id="dont-make-it-sound-too-easy">Don't make it sound too easy</h3>
    <p>{`Don’t assume or make out that the new feature or product will be easy for users to complete or understand. This can alienate users who find new features or products difficult to use.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Setting up your account takes less than 5 minutes.</Do>
  <Dont mdxType="Dont">Setting up your account is a piece of cake.</Dont>
    </Guideline>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We can help you answer any questions around copy or help with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      